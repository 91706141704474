import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import Button from '../Links/Button'
import UseSettings from '../../hooks/use-settings'
import './Form.scss'
import { client } from '../../apollo/client'

const CONTACT_MUTATION = gql`
  mutation CreateSubmissionMutation(
    $clientMutationId: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $message: String!
    $gdprConsent: String!
    $form: String!
  ) {
    createSubmission(
      input: {
        clientMutationId: $clientMutationId
        firstName: $firstName
        lastName: $lastName
        email: $email
        message: $message
        gdprConsent: $gdprConsent
        form: $form
      }
    ) {
      success
      data
    }
  }
`

const Form = ({ formValue = 'Kundkontakt' }) => {
  const { settingsData } = UseSettings()
  const [firstNameValue, setFirstNameValue] = useState('')
  const [lastNameValue, setLastNameValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [messageValue, setMessageValue] = useState('')
  const [gdprConsentValue, setGdprConsentValue] = useState(false)
  const phone = settingsData.wpPage.settings.telefon
  const email = settingsData.wpPage.settings.email
  const gdprText =
    'Genom att skicka iväg min fråga samtycker jag till att de personuppgifter jag angett, liksom eventuella personuppgifter som härrör från min fråga, används av UÅ Padel AB i syfte att behandla min fråga och eventuella följdfrågor.'

  const messageIfError = `
    Namn: ${firstNameValue} ${lastNameValue}%0D
    Formulär: ${formValue}%0D
    Meddelande:%0D
    ${messageValue}%0D
    ---------------------------------------%0D
    [${gdprConsentValue}] - ${gdprText}
  `

  return (
    <>
      <Mutation client={client} mutation={CONTACT_MUTATION}>
        {(createSubmission, { loading, error, data }) => (
          <>
            <form
              className={`form-wrapper ${error ? 'error' : ''} ${
                data ? 'success' : ''
              }`}
              onSubmit={async (event) => {
                event.preventDefault()
                try {
                  await createSubmission({
                    variables: {
                      clientMutationId: '$clientMutationId',
                      firstName: firstNameValue,
                      lastName: lastNameValue,
                      email: emailValue,
                      message: messageValue,
                      gdprConsent: gdprConsentValue
                        ? `${firstNameValue} ${lastNameValue} har accepterat.`
                        : 'nej',
                      form: formValue,
                    },
                  })
                } catch (error) {
                  console.error(error)
                }
              }}
            >
              {/* <div className="gap-3 lg:gap-6">
                <h2 className="h4 title">Skriv till oss</h2>
              </div> */}
              <div className="grid grid-cols-2 gap-3 lg:gap-6">
                <label className="block">
                  <span className="text-white opacity-70">Förnamn</span>
                  <input
                    id="fname"
                    type="text"
                    name="fname"
                    required
                    placeholder="Ditt förnamn"
                    className="input-block"
                    value={firstNameValue}
                    onChange={(event) => {
                      setFirstNameValue(event.target.value)
                    }}
                  />
                </label>

                <label className="block">
                  <span className="text-white opacity-70">Efternamn</span>
                  <input
                    id="lname"
                    type="text"
                    name="lname"
                    placeholder="Ditt efternamn"
                    className="input-block"
                    value={lastNameValue}
                    onChange={(event) => {
                      setLastNameValue(event.target.value)
                    }}
                  />
                </label>
              </div>

              <label className="block">
                <span className="text-white opacity-70">Email</span>
                <input
                  required
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Din email"
                  className="input-block"
                  value={emailValue}
                  onChange={(event) => {
                    setEmailValue(event.target.value)
                  }}
                />
              </label>

              <label className="block">
                <span className="text-white opacity-70">Meddelande</span>
                <textarea
                  required
                  id="message"
                  rows="3"
                  name="message"
                  placeholder="Skriv ett meddelande..."
                  className="input-block"
                  value={messageValue}
                  onChange={(event) => {
                    setMessageValue(event.target.value)
                  }}
                />
              </label>

              <div className="block mb-3">
                <div className="mt-2">
                  <div>
                    <label className="inline-flex items-center">
                      <input
                        required
                        id="gdpr"
                        type="checkbox"
                        className="form-checkbox input-checkbox self-start"
                        name="gdpr"
                        value={gdprConsentValue}
                        onChange={(event) => {
                          setGdprConsentValue(event.target.checked)
                        }}
                      />
                      <span className="ml-3 text-white opacity-70 select-none">
                        {gdprText}
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              {!data && !error && (
                <Button
                  type="submit"
                  disabled={loading}
                  text={
                    loading ? (
                      <div className="w-full flex justify-center">
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </div>
                    ) : (
                      `Skicka meddelande`
                    )
                  }
                />
              )}
              {data && (
                <div className="success form-card">
                  <span>
                    <h5>Meddelandet är skickat 🎉</h5>
                    <p>
                      Tack för ditt meddelande{' '}
                      <b className="text-ua-green-light">{firstNameValue}</b>.
                      Vi försöker att besvara dig så snart som möjligt.
                    </p>
                  </span>
                </div>
              )}
              {error && (
                <div className="error form-card">
                  <h5>Något gick fel</h5>
                  <p>
                    Klicka på knappen "Skicka igen" för att skicka samma
                    meddelande från din vanliga mejlklient.
                  </p>
                  <div className="flex justify-between flex-wrap mt-6">
                    <a
                      href={`mailto:${email}?subject=${formValue}&body=${messageIfError}`}
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      Skicka igen
                    </a>
                    <a href={`tel:${phone}`} target={'_blank'} rel="noreferrer">
                      {phone}
                    </a>
                  </div>
                </div>
              )}
            </form>
          </>
        )}
      </Mutation>
    </>
  )
}

export default Form
