import React from "react";
import Layout from "../components/Layout/Layout";
import PageContainer from "../components/PageContainer/PageContainer";
import List from "../components/List/List";
import ListItem from "../components/List/ListItem";
import Heading from "../components/PageContainer/Heading";
import UseSettings from "../hooks/use-settings";
import Seo from "../components/seo";
import { graphql } from "gatsby";
import Form from "../components/Form/Form";
import Map from "../components/Map/Map";

const kontakt = ({ data: { page } }) => {
  const { settingsData } = UseSettings();
  const adress = settingsData.wpPage.settings.adress;
  const phone = settingsData.wpPage.settings.telefon;
  const orgnr = settingsData.wpPage.settings.orgnr;
  const email = settingsData.wpPage.settings.email;
  const { text, title } = page.header_twocol.header;
  return (
    <Layout>
      <Seo title={title} description={text} />
      <PageContainer content={<Map />} nodark>
        <div className="flex flex-col gap-6 w-full py-24 lg:py-0">
          <Heading title={title} text={text} />
          <List>
            <ListItem first={"Adress"} second={adress} />
            <ListItem first={"Telefon"} second={phone} />
            <ListItem first={"Org.nr"} second={orgnr} />
            <ListItem first={"Email"} second={email} />
          </List>
          <Form />
        </div>
      </PageContainer>
    </Layout>
  );
};

export default kontakt;

export const pageQuery = graphql`
  query ContactPage {
    page: wpPage(title: { eq: "Kontakt" }) {
      id
      header_twocol {
        header {
          text
          title
          image {
            localFile {
              childImageSharp {
                original {
                  src
                }
                gatsbyImageData(
                  quality: 100
                  height: 600
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
